<script>
import TaskList from "components/suporte/components/task/List"
import {list} from "@/domain/tarefas/services"
import ECol from "components/layout/components/Col"
import ERow from "components/layout/components/Row"
import {date, debounce, UInput, USelect} from "uloc-vue"
import {ErpSField} from 'uloc-vue-plugin-erp'
import PersonSelect from "components/suporte/components/projetos/include/PersonSelect"
import TypeSelect from "components/suporte/components/task/include/TypeSelect"
import StatusSelect from "components/suporte/components/task/include/StatusSelect"
import PrioritySelect from "components/suporte/components/task/include/PrioritySelect"
import {datePtToEn} from "@/utils/date"
import DateInput from "@/reuse/input/Date"

// import QueueSelect from "components/suporte/components/task/include/QueueSelect"
export default {
  name: 'TaskListPage',
  mixins: [],
  props: {
    fila: {required: false},
    project: {required: false},
    mode: {type: String, default: null},
    autoload: {type: Boolean, default: true},
    isGerencia: {type: Boolean, default: false},
    tableClass: {required: false},
    extraFilters: {type: Array, default: null}
  },
  components: {
    TaskList,
    ECol,
    ERow,
    UInput,
    USelect,
    PersonSelect,
    TypeSelect,
    // QueueSelect,
    ErpSField,
    StatusSelect,
    PrioritySelect,
    DateInput
  },
  data () {
    return {
      maisFiltros: false,
      table: {
        serverData: [],
        selected: [],
        loading: false,
        filters: {
          id: null,
          busca: null,
          data1: null,
          data2: null,
          tipoData: 'deadline',
          internalStatus: ['opened'],
          author: null,
          assigned: null,
          cliente: null,
          fila: this.fila,
          project: this.project,
          type: null,
          priority: null,
          status: null,
          active: null
        },
        serverPagination: {
          page: 1,
          rowsNumber: 0,
          rowsPerPage: 100
        }
      }
    }
  },
  mounted() {
    this.autoload && this.load()
  },
  watch: {
    fila (v) {
      this.table.filters.fila = v
      this.$nextTick(() => {
        this.load()
      })
    },
    'table.filters.internalStatus' (v) {
      this.$nextTick(() => {
        this.load()
      })
    },
    'table.filters.busca': debounce(function () {
      this.load()
    }, 500)
  },
  computed: {
  },
  methods: {
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.table.filters.data1) {
        if (this.table.filters.data1.length < 10 || (!this.table.filters.data2 || this.table.filters.data2.length < 10)) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.table.filters.data1)
        data2 = datePtToEn(this.table.filters.data2)
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.table.filters.tipoData && extraFilters.push(`&typeDate=${this.table.filters.tipoData}`)

      this.table.filters.busca && extraFilters.push(`&search=${String(this.table.filters.busca).replace('#', '_hash-')}`)

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      !this.fila && this.table.filters.internalStatus && extraFilters.push(`&internalStatus=${this.table.filters.internalStatus.join(',')}`)
      this.table.filters.tipo && extraFilters.push(`&type=${this.table.filters.tipo}`)
      this.table.filters.active && extraFilters.push(`&active=${this.table.filters.active}`)
      this.table.filters.cliente && extraFilters.push(`&client=${this.table.filters.cliente.id}`)
      this.table.filters.assigned && extraFilters.push(`&assigned=${this.table.filters.assigned.id}`)
      this.table.filters.lider && extraFilters.push(`&owner=${this.table.filters.lider.id}`)
      this.table.filters.permission && extraFilters.push(`&permission=${this.table.filters.permission}`)
      this.table.filters.public && extraFilters.push(`&public=${this.table.filters.public}`)
      this.table.filters.project && extraFilters.push(`&project=${this.table.filters.project}`)
      this.table.filters.priority && extraFilters.push(`&priority=${this.table.filters.priority.id}`)
      this.table.filters.type && extraFilters.push(`&type=${this.table.filters.type.id}`)
      this.table.filters.fila && extraFilters.push(`&queue=${this.table.filters.fila.replace('id:', '')}`)
      // Filtra somente tarefas vencidas
      this.table.filters.dueFilter && extraFilters.push(`&dueFilter=${this.table.filters.dueFilter}`)

      /*if (Array.isArray(this.tags) && this.tags.length > 0) {
        extraFilters.push(`&tags=${this.tags.join(',')}`)
      }*/

      if (this.extraFilters && Array.isArray(this.extraFilters)) {
        this.extraFilters.map(ef => {
          extraFilters.push('&' + ef)
        })
      }

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : true}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, this.isSuporte, this.isGerencia)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    load() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.busca
      })
    }
  }
}
</script>

<template>
  <div>
    <div class="stask-search suporte-chamado-window e-input-modern">
      <e-row mr>
        <e-col style="max-width: 300px"><u-input placeholder="Pesquisar" @keydown.enter="load" v-model="table.filters.busca" hide-underline inverted-light class="no-box-shadow" :after="[{icon: 'search'}]" /></e-col>
        <e-col v-if="!fila" style="max-width: 200px">
          <u-select
              hide-underline inverted-light class="no-box-shadow"
              multiple v-model="table.filters.internalStatus" :options="[
              {label: 'Abertas', value: 'opened'},
              {label: 'Fechadas', value: 'closed'},
          ]" />
        </e-col>
        <e-col>
            <type-select placeholder="Tipo" v-model="table.filters.type" :is-gerencia="isGerencia" />
        </e-col>
        <e-col class="flex justify-between">
          <u-btn @click="load" label="Buscar" flat no-caps class="bg-grey-1" />
        </e-col>
        <e-col v-if="!isGerencia" class="flex items-center justify-end">
          <u-btn @click="maisFiltros = !maisFiltros" flat no-caps dense>
            <div v-if="maisFiltros">
              <span class="font-12">Menos filtros</span> <i class="fal fa-filter m-l-sm font-12 text-positive"></i>
            </div>
            <div v-else><span class="font-12">Mais filtros</span> <i class="fal fa-filter m-l-sm font-12"></i></div>
          </u-btn>
        </e-col>
      </e-row>
      <div v-if="maisFiltros">
      <e-row mr>
        <e-col>
          <erp-s-field label="Relator">
            <person-select v-model="table.filters.author" />
          </erp-s-field>
        </e-col>
        <e-col>
          <erp-s-field label="Responsável">
            <person-select v-model="table.filters.assigned" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 200px">
          <erp-s-field label="Status">
            <status-select v-model="table.filters.status" />
          </erp-s-field>
        </e-col>
        <e-col style="max-width: 200px">
          <erp-s-field label="Prioridade">
            <priority-select v-model="table.filters.priority" />
          </erp-s-field>
        </e-col>
      </e-row>
      <e-row mr>
        <e-col style="min-width: 150px">
          <date-input @keydown.enter.native="load" label="Data (De)" v-model="table.filters.data1"/>
        </e-col>
        <e-col style="min-width: 150px">
          <date-input @keydown.enter.native="load" label="Data (Até)" v-model="table.filters.data2"/>
        </e-col>
        <e-col style="min-width: 150px">
          <erp-s-field
              label="Filtro Data"
          >
            <u-select
                hide-underline inverted-light class="no-box-shadow"
                :options="[
                          {label: 'Criação', value: 'createdAt'},
                          {label: 'Início', value: 'startTime'},
                          {label: 'Prazo', value: 'deadline'},
                      ]"
                v-model="table.filters.tipoData" />
          </erp-s-field>
        </e-col>
      </e-row>
      </div>
    </div>
    <task-list :data="table.serverData" :project="project" :request="request" :options="table" :option-column="false" :mode="mode" :is-gerencia="isGerencia" />
  </div>
</template>
